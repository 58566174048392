import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { fadeIn } from "../variants";
import hosting from "../assets/Hosting.png";
import WhatsAppButton from "../components/Whathsapp";

const Hosting = () => {
  const navigate = useNavigate();

  const handleNavigateToContact = () => {
    navigate("/");
    setTimeout(() => {
      const section = document.querySelector("#contacto");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  return (
    <div className='bg-site bg-no-repeat bg-cover overflow-hidden min-h-[40rem] mt-4 '>
      <section className='py-16 lg:section' id='contacto'>
        <div className='container mx-auto'>
          <div className='flex flex-col lg:flex-row'>
            {/* Texto e imagen */}
            <motion.div
              variants={fadeIn("right", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className='flex-1 flex justify-start items-center'
            >
              <div>
                <h2 className='text-[40px] lg:text-[60px] leading-none mb-12 text-center flex justify-center'>
                  Hosting <br />Dominio y Correo 
                </h2>
                <img className='mb-6' src={hosting} width={450} height={450} alt="hosting dominio web" />
              </div>
            </motion.div>

            {/* Texto descriptivo */}
            <motion.div
              variants={fadeIn("left", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{
                once: false,
                amount: 0.3,
              }}
              className="flex-1"
            >
              <p className="text-[25px] font-bold leading-[0.8] lg:text-[40px] text-center mb-4">
                ¿Por qué es crucial Dominio, Hosting y Correo?
              </p>
              <div className="mb-6 text-[20px] lg:text-[22px]">
                <div className="flex mb-3">
                  <p>
                  En el mundo digital, un hosting confiable, dominio personalizado, y correo corporativo son esenciales. El hosting asegura que tu sitio esté siempre accesible; el dominio es la identidad de tu negocio en la web, y el correo corporativo refuerza la profesionalidad y confianza en las comunicaciones de tu empresa.
                  </p>
                </div>
                <div className="flex mb-3">
                  <p>
                    Al elegirnos para la creación de tu sitio web, obtienes:
                 
                      <li>Hosting confiable para tu sitio web.</li>
                      <li>Correo corporativo profesional.</li>
                      <li>Dominio personalizado para tu marca.</li>
                      <li>Soporte técnico especializado.</li>
                 
                  </p>
                </div>
                <div className="flex mb-3">
                  <p>
                    No dejes que la competencia te supere. Invierte en un hosting, dominio y correo personalizado para tu empresa!
                  </p>
                </div>
              </div>
              <div className="flex gap-x-8 items-center">
                <button className="btn btn-lg" onClick={handleNavigateToContact}>
                  Contáctanos
                </button>
              </div>
            </motion.div>
          </div>
        </div>
        <WhatsAppButton />
      </section>
    </div>
  );
};

export default Hosting;
