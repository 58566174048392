import React from "react";
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom";
import {
  Footer as FlowbiteFooter,
  FooterLink,
  FooterLinkGroup,
  FooterTitle,
} from "flowbite-react";
import {
  BsFacebook,
  BsInstagram,
  BsWhatsapp,
  BsTelegram
} from "react-icons/bs";
import logo from "../assets/logonexushd.png";

export default function Footer() {
  const navigate = useNavigate();

  const handleNavigation = (sectionId) => {
    navigate("/");
    setTimeout(() => {
      const section = document.querySelector(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  return (
    <FlowbiteFooter container className="bg-gray-900 text-white py-10 justify-between text-[20px] lg:text-[22px]">
      {/* Section 1: Logo and Image */}
      <div className="flex flex-col items-center md:items-start">
        <img src={logo} alt="Your Logo" className="mb-4 w-52 h-auto" />
        <p className="text-base text-center md:text-left font-medium text-[20px] lg:text-[22px]">
          Nexus™ - Elevamos tu Negocio.
        </p>
      </div>

      {/* Section 2: Navigation Links 1 */}
      <div className="flex flex-col">
        <FooterTitle title="Menú" className="mb-4 text-lg font-semibold text-[20px] lg:text-[22px]" />
        <FooterLinkGroup col className="space-y-2 text-[18px] lg:text-[20px] font-medium">

          <FooterLink onClick={() => handleNavigation("#home")} className="cursor-pointer">Home</FooterLink>
          <FooterLink onClick={() => handleNavigation("#servicios")} className="cursor-pointer">Servicios</FooterLink>
          <FooterLink onClick={() => handleNavigation("#nosotros")} className="cursor-pointer">Nosotros</FooterLink>
          <FooterLink onClick={() => handleNavigation("#contacto")} className="cursor-pointer">Contactanos</FooterLink>
        </FooterLinkGroup>
      </div>

      {/* Section 3: Navigation Links 2 */}
      <div className="flex flex-col">
        <FooterTitle title="Servicios" className="mb-4 text-lg font-semibold text-[20px] lg:text-[22px]" />
        <FooterLinkGroup col className="space-y-2 text-[18px] lg:text-[20px] font-medium">
          <Link to="/diseno-web-profesional">
          <FooterLink href="/diseno-web-profesional" className="cursor-pointer">Diseño Web</FooterLink>
          </Link>
          <Link to="/publicidad-online">
          <FooterLink href="/publicidad-online" className="cursor-pointer">Publicidad Online</FooterLink>
          </Link>
          <Link to="/tiendas-online">
          <FooterLink href="/tiendas-online" className="cursor-pointer">Tienda Online</FooterLink>
          </Link>
          <Link to="/marketing-redes-sociales">
          <FooterLink href="/marketing-redes-sociales" className="cursor-pointer">Redes Sociales</FooterLink>
          </Link>
        </FooterLinkGroup>
      </div>

      {/* Footer Bottom */}
      <div className="mt-4 flex space-x-6">
        <a href="https://www.facebook.com/profile.php?id=61564216423187" className="text-white hover:text-accent" target="_blank" rel="noopener noreferrer">
          <BsFacebook size={20} />
        </a>
        <a href="https://www.instagram.com/nexusmarketingd/" className="text-white hover:text-accent" target="_blank" rel="noopener noreferrer">
          <BsInstagram size={20} />
        </a>
        <a href="https://wa.me/5492613866469" className="text-white hover:text-accent" target="_blank" rel="noopener noreferrer">
          <BsWhatsapp size={20} />
        </a>
        <a href="#" className="text-white hover:text-accent"><BsTelegram size={23} /></a>
      </div>
    </FlowbiteFooter>
  );
}
