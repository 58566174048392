import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { fadeIn } from "../variants";
import ads from "../assets/googleads.png"
import WhatsAppButton from "../components/Whathsapp";

const GoogleAds = () => {
  const navigate = useNavigate();

  const handleNavigateToContact = () => {
    navigate("/");
    setTimeout(() => {
      const section = document.querySelector("#contacto");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  return (
    <div className='bg-site bg-no-repeat bg-cover overflow-hidden min-h-[40rem] mt-4'>
      <section className='py-16 lg:section' id='contacto'>
        <div className='container mx-auto'>
          <div className='flex flex-col lg:flex-row'>
            {/* Texto e imagen */}
            <motion.div
              variants={fadeIn("right", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className='flex-1 flex justify-start items-center'
            >
              <div>
                <h2 className='text-[40px] lg:text-[60px] leading-none mb-12 text-center flex justify-center'>
                  Publicidad<br />Online
                </h2>
                <img className='mb-6' src={ads} width={450} height={450} alt="publicidad online" />
              </div>
            </motion.div>

            {/* Texto descriptivo */}
            <motion.div
              variants={fadeIn("left", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{
                once: false,
                amount: 0.3,
              }}
              className="flex-1"
            >
              <p className="text-[25px] font-bold leading-[0.8] lg:text-[40px] text-center mb-4">
                ¿Por qué deberías invertir en campañas publicitarias?
              </p>
              <div className="mb-6 text-[20px] lg:text-[22px]">
                <div className="flex mb-3">
                  <p>
                    En un entorno digital cada vez más competitivo, una estrategia de paid media eficaz es clave para destacarte y alcanzar tus objetivos. Nuestro servicio de campañas publicitarias está diseñado para maximizar tu presencia en plataformas como Google, Meta (Facebook e Instagram) y LinkedIn.
                  </p>
                </div>
                <div className="flex mb-3">
                  <p>
                    Al elegirnos, obtienes:
                    <li>Estrategias de paid media que potencian tu visibilidad y engagement.</li>
                    <li>Campañas publicitarias adaptadas a las necesidades específicas de tu negocio.</li>
                    <li>Optimización continua de ads en Google, Meta y LinkedIn para maximizar el retorno de inversión.</li>
                    <li>Soporte integral para asegurar el éxito de tus campañas publicitarias.</li>

                  </p>
                </div>
                <div className="flex mb-3">
                  <p>
                    No dejes que la competencia te supere. Invierte en campañas publicitarias efectivas y lleva tu negocio al siguiente nivel.
                  </p>
                </div>
              </div>
              <div className="flex gap-x-8 items-center">
                <button className="btn btn-lg" onClick={handleNavigateToContact}>
                  Contáctanos
                </button>
              </div>
            </motion.div>
          </div>
        </div>
        <WhatsAppButton />
      </section>
    </div>
  );
};

export default GoogleAds;
