import React from 'react';
import { motion } from 'framer-motion';
import whatsapp from "../assets/WhatsApp.webp";

const WhatsAppButton = () => {
    return (
        <a
            href="https://wa.me/5492613866469"
            className="fixed bottom-24 right-4 transition duration-300 flex items-center bg-black pl-4 rounded-full"
            target="_blank"
            rel="noopener noreferrer"
        >
            <p className='text-white mr-2 font-medium'>Contáctanos</p>
            <motion.img 
                src={whatsapp} 
                width={55} 
                alt="WhatsApp" 
                animate={{ y: [0, -10, 0] }} 
                transition={{ duration: 3, repeat: Infinity, repeatDelay: 2 }}
            />
        </a>
    );
};

export default WhatsAppButton;
