import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import { motion } from 'framer-motion';
import { fadeIn } from "../variants";
import ecommerce from "../assets/ecommerce.png";
import WhatsAppButton from "../components/Whathsapp";

const Ecommerce = () => {
  const navigate = useNavigate();

  const handleNavigateToContact = () => {
    navigate("/");
    setTimeout(() => {
      const section = document.querySelector("#contacto");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  return (
    <div className='bg-site bg-no-repeat bg-cover overflow-hidden min-h-[40rem] mt-4'>
      <section className='py-16 lg:section' id='contacto'>
        <div className='container mx-auto'>
          <div className='flex flex-col lg:flex-row'>
            {/* Texto e imagen */}
            <motion.div
              variants={fadeIn("right", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className='flex-1 flex justify-start items-center'
            >
              <div>
                <h2 className='text-[40px] lg:text-[60px] leading-none mb-12 text-center flex justify-center'>
                  Creación de <br />Tiendas Online 
                </h2>
                <img className='mb-6' src={ecommerce} width={450} height={450} alt="tienda online ecommerce" />
              </div>
            </motion.div>

            {/* Texto descriptivo */}
            <motion.div
              variants={fadeIn("left", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{
                once: false,
                amount: 0.3,
              }}
              className="flex-1"
            >
              <p className="text-[25px] font-bold leading-[0.8] lg:text-[40px] text-center mb-4">
                ¿Por qué deberías tener una tienda online profesional?
              </p>
              <div className="mb-6 text-[20px] lg:text-[22px]">
                <div className="flex mb-3">
                  <p>
                    En el mundo digital, tu tienda online es esencial para expandir tu negocio y aumentar las ventas. Nuestro servicio de desarrollo de e-commerce crea tiendas online personalizadas, seguras y optimizadas para SEO, asegurando una experiencia de compra fluida para tus clientes.
                  </p>
                </div>
                <div className="flex mb-3">
                  <p>
                    Al elegirnos para tu E-commerce obtienes:
               
                      <li>Diseños personalizados que reflejan tu marca.</li>
                      <li>Sitios web responsivos, adaptados a móviles, tablets y ordenadores.</li>
                      <li>Plataformas seguras con integraciones de pago confiables.</li>
                      <li>Experiencia de usuario optimizada para aumentar la conversión.</li>
                   
                  </p>
                </div>
                <div className="flex mb-3">
                  <p>
                    No dejes que tu competencia te supere. Invierte en una tienda online profesional y aumenta tus ventas.
                  </p>
                </div>
              </div>
              <div className="flex gap-x-8 items-center">
                <button className="btn btn-lg" onClick={handleNavigateToContact}>
                  Contáctanos
                </button>
              </div>
            </motion.div>
          </div>
        </div>
        <WhatsAppButton />
      </section>
    </div>
  );
};

export default Ecommerce;
