import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { fadeIn } from "../variants";
import software from "../assets/Software.png";
import WhatsAppButton from "../components/Whathsapp";

const Software = () => {
  const navigate = useNavigate();

  const handleNavigateToContact = () => {
    navigate("/");
    setTimeout(() => {
      const section = document.querySelector("#contacto");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  return (
    <div className='bg-site bg-no-repeat bg-cover overflow-hidden min-h-[40rem] mt-4 '>
      <section className='py-16 lg:section' id='contacto'>
        <div className='container mx-auto'>
          <div className='flex flex-col lg:flex-row'>
            {/* Texto e imagen */}
            <motion.div
              variants={fadeIn("right", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className='flex-1 flex justify-start items-center'
            >
              <div>
                <h2 className='text-[40px] lg:text-[60px] leading-none mb-12 text-center flex justify-center'>
                  Desarrollo de <br />Software 
                </h2>
                <img className='mb-6' src={software} width={450} height={450} alt="software programa" />
              </div>
            </motion.div>

            {/* Texto descriptivo */}
            <motion.div
              variants={fadeIn("left", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{
                once: false,
                amount: 0.3,
              }}
              className="flex-1"
            >
              <p className="text-[25px] font-bold leading-[0.8] lg:text-[40px] text-center mb-4">
                ¿Por qué debería invertir en un software profesional?
              </p>
              <div className="mb-6 text-[20px] lg:text-[22px]">
                <div className="flex mb-3">
                  <p>
                  Impulsa tu negocio con software a medida. Creamos soluciones innovadoras y seguras, adaptadas a tus necesidades, para transformar tus ideas en aplicaciones eficientes y efectivas. ¡Destaca con tecnología de vanguardia!
                  </p>
                </div>
<div className="flex mb-3">
  <p>
    Con nuestro desarrollo de software, obtienes:
    <li>Aplicaciones personalizadas que se ajustan a tus necesidades.</li>
    <li>Soluciones seguras y escalables.</li>
    <li>Integración fluida con tus herramientas actuales.</li>
    <li>Soporte técnico dedicado para asegurar tu éxito.</li>
  </p>
</div>
<div className="flex mb-3">
  <p>
    No te quedes atrás. Invierte en software de vanguardia y lleva tu negocio al siguiente nivel.
  </p>
</div>

              </div>
              <div className="flex gap-x-8 items-center">
                <button className="btn btn-lg" onClick={handleNavigateToContact}>
                  Contáctanos
                </button>
              </div>
            </motion.div>
          </div>
        </div>
        <WhatsAppButton />
      </section>
    </div>
  );
};

export default Software;
