import React from 'react';
import Swal from 'sweetalert2';
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import formImage from "../assets/form.png";

const Contact = () => {
  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "f82a5305-2763-4a1c-ba93-5668d2aa499f");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: json
    }).then((res) => res.json());

    if (res.success) {
      Swal.fire({
        title: 'Mensaje enviado',
        text: 'Tu mensaje ha sido enviado exitosamente!',
        icon: 'success',
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'swal2-confirm-btn'
        }
      });
      event.target.reset();
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Hubo un error al enviar tu mensaje. Inténtalo nuevamente.',
        icon: 'error',
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'swal2-confirm-btn'
        }
      });
    }
  };

  return (
    <div id='contacto1'>
      <section className='py-16 lg:section' id='contacto'>
        <div className='container mx-auto'>
          <div className='flex flex-col lg:flex-row'>
            <motion.div
              variants={fadeIn("right", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className='flex-1 flex justify-start items-center'
            >
              <div>
                <h2 className='text-[45px] lg:text-[90px] leading-none mb-12 text-center flex justify-center'>Cuéntanos <br />sobre tu idea</h2>
                <img className='mb-6' src={formImage} width={400} height={400} alt="Form illustration" />
              </div>
            </motion.div>
            <motion.form
              onSubmit={onSubmit}
              variants={fadeIn("left", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className='flex-1 border rounded-2x1 flex flex-col gap-y-6 pb-24 p-6 items-start formulario'
            >
              <input
                className='text-[20px] lg:text-[22px] bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all'
                type='text'
                name='email'
                placeholder='Tu email'
                required
              />
              <input
                className='text-[20px] lg:text-[22px] bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all'
                type='text'
                name='name'
                placeholder='Tu Nombre'
                required
              />
              <select
                className='text-[20px] lg:text-[22px] bg-black border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all'
                name='topic'
                required
              >
                <option value="" disabled selected>Selecciona una opcion</option>
                <option value="Diseño Web">Diseño Web</option>
                <option value="Google Ads">Publicidad Online</option>
                <option value="E-commerce">E-commerce</option>
                <option value="Redes Sociales">Redes Sociales</option>
                <option value="Desarrollo de Software">Desarrollo de Software</option>
                <option value="Hosting, Dominio y Correo">Hosting, Dominio y Correo</option>
                <option value="Otro">Otro</option>
              </select>
              <textarea
                name='message'
                placeholder='Tu mensaje'
                className='text-[20px] lg:text-[22px] bg-transparent border-b py-12 outline-none w-full placeholder:text-white focus:border-accent transition-all resize-none mb-12'
                required
              ></textarea>
              <button className='btn btn-lg '>Enviar Mensaje</button>
            </motion.form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
