import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { fadeIn } from "../variants";
import Marketing from "../assets/Marketing.png";
import WhatsAppButton from "../components/Whathsapp";

const RedesSociales = () => {
  const navigate = useNavigate();

  const handleNavigateToContact = () => {
    navigate("/");
    setTimeout(() => {
      const section = document.querySelector("#contacto");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  return (
    <div className='bg-site bg-no-repeat bg-cover overflow-hidden min-h-[40rem] mt-4'>
      <section className='py-16 lg:section' id='contacto'>
        <div className='container mx-auto'>
          <div className='flex flex-col lg:flex-row'>
            {/* Texto e imagen */}
            <motion.div
              variants={fadeIn("right", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className='flex-1 flex justify-start items-center'
            >
              <div>
                <h2 className='text-[40px] lg:text-[60px] leading-none mb-12 text-center flex justify-center'>
                  Redes <br />Sociales 
                </h2>
                <img className='mb-6' src={Marketing} width={450} height={450} alt="marketing digital" />
              </div>
            </motion.div>

            {/* Texto descriptivo */}
            <motion.div
              variants={fadeIn("left", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{
                once: false,
                amount: 0.3,
              }}
              className="flex-1"
            >
              <p className="text-[25px] font-bold leading-[0.8] lg:text-[40px] text-center mb-4">
                ¿Por qué es crucial una estrategia de marketing en redes sociales?
              </p>
              <div className="mb-6 text-[20px] lg:text-[22px]">
                <div className="flex mb-3">
                  <p>
                    En el mundo digital, una presencia sólida en redes sociales es esencial para conectar con tu audiencia y construir tu marca. Nuestro servicio de marketing digital en redes sociales te ayuda a crecer con estrategias personalizadas que impulsan la interacción y aumentan tu alcance.
                  </p>
                </div>
                <div className="flex mb-3">
                  <p>
                    Al elegirnos, obtienes:
                    
                      <li>Estrategias de contenido alineadas con tus objetivos.</li>
                      <li>Gestión integral de redes sociales para aumentar tu presencia online.</li>
                      <li>Análisis de rendimiento para ajustar y optimizar campañas.</li>
                      <li>Mayor interacción y seguidores para fortalecer tu marca.</li>
                    
                  </p>
                </div>
                <div className="flex mb-3">
                  <p>
                    No dejes que la competencia te adelante. Invierte en marketing digital en redes sociales y lleva tu negocio al siguiente nivel.
                  </p>
                </div>
              </div>
              <div className="flex gap-x-8 items-center">
                <button className="btn btn-lg" onClick={handleNavigateToContact}>
                  Contáctanos
                </button>
              </div>
            </motion.div>
          </div>
        </div>
        <WhatsAppButton />
      </section>
    </div>
  );
};

export default RedesSociales;
