import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Importa useNavigate
import { Link as ScrollLink } from 'react-scroll';
import nexus from "../assets/logonexushd.png";
import burguer from "../assets/menu.png";
import cancelar from "../assets/cerrar.png";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate(); // Hook para la navegación

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };



  const handleNavigation = (section) => {
    navigate('/');
    setTimeout(() => {
      document.getElementById(section).scrollIntoView({ behavior: 'smooth' });
    }, 100); // Timeout para asegurarse que la navegación ocurra antes del scroll

    if (isOpen) {
      toggleMenu(); // Cierra el menú si está abierto
    }
  };

  return (
    <header className="w-full h-16 bg-black drop-shadow-lg z-50">
      <div className="container mx-auto px-4 flex justify-between items-center h-full">
        <Link to="/" className="text-yellow-400 text-xl font-bold italic">
          <img src={nexus} alt="" width={215} className='logomd' />
        </Link>
        <nav
          className={`fixed top-0 right-0 px-10 py-16 bg-gray-800 z-50 w-full md:relative md:flex md:h-auto md:w-auto md:p-0 md:bg-transparent h-52 ${
            isOpen ? 'block' : 'hidden'
          } md:block`}
        >
          <ul className="flex flex-col md:flex-row md:space-x-6">
            <li className="md:hidden absolute top-4 right-6 text-4xl">
              <button onClick={toggleMenu}><img src={cancelar} alt="" width={30} /></button>
            </li>
            <li>
              <ScrollLink
                to="home"
                smooth={true}
                duration={500}
                className="cursor-pointer text-white text-[22px] lg:text-[24px] opacity-80 hover:opacity-100 duration-300 font-semibold"
                onClick={() => handleNavigation('home')}
              >
                Home
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="servicios"
                smooth={true}
                duration={500}
                className="cursor-pointer text-white text-[22px] lg:text-[24px] opacity-80 hover:opacity-100 duration-300 font-semibold"
                onClick={() => handleNavigation('servicios')}
              >
                Servicios
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="nosotros"
                smooth={true}
                duration={500}
                className="cursor-pointer text-[22px] lg:text-[24px] text-white opacity-80 hover:opacity-100 duration-300 font-semibold"
                onClick={() => handleNavigation('nosotros')}
              >
                Nosotros
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="contacto1"
                smooth={true}
                duration={500}
                className="cursor-pointer text-[22px] lg:text-[24px] text-white opacity-80 hover:opacity-100 duration-300 font-semibold"
                onClick={() => handleNavigation('contacto1')}
              >
                Contacto
              </ScrollLink>
            </li>
          </ul>
        </nav>
        <div className="flex items-center md:hidden">
          <button
            className="text-white text-4xl font-bold duration-300"
            onClick={toggleMenu}
          >
            <img src={burguer} alt="" width={30} />
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
