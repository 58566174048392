import React from 'react';
import { Link } from "react-router-dom"
//motion
import { motion } from "framer-motion"
//variants
import { fadeIn } from "../variants"
import webdesign from "../assets/web4.png"
import redesociales from "../assets/social.png"
import ecommerce2 from "../assets/tienda3.png"
import ads from "../assets/campana.png"
import software from "../assets/software3.png"
import hosting from "../assets/dominio1.png"


const Servicios = () => {
    return (
        <>
            <div className=' flex flex-col items-center' id='servicios'>
                {/* <p className=' flex flex-col items-center text-[45px] font-bold leading-[0.8] lg:text-[60px] mb-10 mt-12'>Nuestros servicios</p>  */}
                {/* from-fuchsia-500 to-cyan-500 */}
                <p className='flex  items-center text-[45px] font-bold leading-[0.8] lg:text-[60px] mb-10 mt-12'>
  Nuestros&nbsp;
  <span className='inline-block relative bg-gradient-to-r from-fuchsia-500 to-cyan-500 bg-clip-text text-transparent dark:text-white'>
    Servicios
    {/* <img
      src={curve}
      alt=""
      className="absolute top-full left-0 w-full xl:-mt-2"
      width={624}
      height={28}
    /> */}
  </span>
</p>
                <motion.p
                    variants={fadeIn("up", 0.3)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: false, amount: 0.7 }}
                    className=' mb-5 text-[20px] lg:text-[22px] text-center w-[85%]'>
                    Contamos con un equipo de expertos digitales que está dedicado a impulsar tu marca al siguiente nivel con estrategias innovadoras y efectivas.
                </motion.p>
            </div>
            <div className='cajas flex flex-wrap justify-center items-center p-4'>

                <Link to="/diseno-web-profesional"
                    className="m-5 max-w-sm p-6 bg-gray-900 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 text-[20px] lg:text-[22px] hover:scale-105 hover:shadow-lg transition-transform duration-300">
                    <img className="w-20 h-20 text-gray-500 dark:text-gray-400 mb-3" src={webdesign} alt="desarrollo de software" aria-hidden="true" />
                    <motion.h5
                        variants={fadeIn("right", 0.3)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.7 }}
                        className="mb-2 text-2xl font-semibold tracking-tight text-white dark:text-white">
                        Diseño Web
                    </motion.h5>
                    <p className="mb-3 font-normal text-white dark:text-gray-400">
                        Nos encargamos del diseño de tu sitio web o aplicación abordando todas las necesidades de tu empresa. Desarrollamos sitios de carga rápida y eficientes para el SEO.
                    </p>
                    <span className="inline-flex font-medium items-center text-blue-600 hover:underline bg-gradient-to-r from-fuchsia-500 to-cyan-500 bg-clip-text text-transparent">
                        Ver Más
                        <svg className="w-3 h-3 ms-2.5 rtl:rotate-[270deg] ml-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
                        </svg>
                    </span>
                </Link>

                <Link to="/publicidad-online"
                    className="m-5 max-w-sm p-6 bg-gray-900 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 text-[20px] lg:text-[22px] hover:scale-105 hover:shadow-lg transition-transform duration-300">
                    <img className="w-20 h-20 text-white dark:text-gray-400 mb-3 border-r-amber-300" src={ads} alt="ads publicidad seo" aria-hidden="true" />
                    <motion.h5
                        variants={fadeIn("right", 0.3)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.7 }}
                        className="mb-2 text-2xl font-semibold tracking-tight text-white dark:text-white">
                        Publicidad Online
                    </motion.h5>
                    <p className="mb-3 font-normal text-white dark:text-gray-400">
                    Impulsamos tu negocio con campañas online efectivas en Instagram,Facebook y Google Ads y LinkedIn, maximizando tu alcance y resultados. 
                    </p>
                    <span className="inline-flex font-medium items-center text-blue-600 hover:underline bg-gradient-to-r from-fuchsia-500 to-cyan-500 bg-clip-text text-transparent">
                        Ver Más
                        <svg className="w-3 h-3 ms-2.5 rtl:rotate-[270deg] ml-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
                        </svg>
                    </span>
                </Link>
              

                <Link to="/tiendas-online"
                    className="m-5 max-w-sm p-6 bg-gray-900 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 text-[20px] lg:text-[22px] hover:scale-105 hover:shadow-lg transition-transform duration-300">
                    <img className="w-20 h-20 text-white dark:text-gray-400 mb-3" src={ecommerce2} alt="ecommerce tienda online" aria-hidden="true" />
                    <motion.h5
                        variants={fadeIn("right", 0.3)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.7 }}
                        className="mb-2 text-2xl font-semibold tracking-tight text-white dark:text-white">
                        Tienda Online
                    </motion.h5>
                    <p className="mb-3 font-normal text-white dark:text-gray-400">
                        ¿Ventas online? Somos especialistas. Creamos tu tienda online y gestionamos su mantenimiento, y actualizaciones, optimizando las conversiones y las ventas.
                    </p>
                    <span className="inline-flex font-medium items-center text-blue-600 hover:underline bg-gradient-to-r from-fuchsia-500 to-cyan-500 bg-clip-text text-transparent">
                        Ver Más
                        <svg className="w-3 h-3 ms-2.5 rtl:rotate-[270deg] ml-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
                        </svg>
                    </span>
                </Link>

                <Link to="/marketing-redes-sociales"
                    className="m-5 max-w-sm p-6 bg-gray-900 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 text-[20px] lg:text-[22px] hover:scale-105 hover:shadow-lg transition-transform duration-300">
                    <img className="w-20 h-20 text-white dark:text-gray-400 mb-3" src={redesociales} alt="redes sociales digital" aria-hidden="true" />
                    <motion.h5
                        variants={fadeIn("right", 0.3)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.7 }}
                        className="mb-2 text-2xl font-semibold tracking-tight text-white dark:text-white">
                        Redes Sociales
                    </motion.h5>
                    <p className="mb-3 font-normal text-white dark:text-gray-400">
                        Activamos y gestionamos tu presencia online en las redes sociales más importantes del momento, incluyendo Instagram, Facebook, TikTok, LinkedIn, y más.
                    </p>
                    <span className="inline-flex font-medium items-center text-blue-600 hover:underline bg-gradient-to-r from-fuchsia-500 to-cyan-500 bg-clip-text text-transparent">
                        Ver Más
                        <svg className="w-3 h-3 ms-2.5 rtl:rotate-[270deg] ml-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
                        </svg>
                    </span>
                </Link>
                <Link to="/desarrollo-software"
                    className="m-5 max-w-sm p-6 bg-gray-900 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 text-[20px] lg:text-[22px] hover:scale-105 hover:shadow-lg transition-transform duration-300">
                    <img className="w-20 h-20 text-white dark:text-gray-400 mb-3" src={software} alt="desarrollo software medida" aria-hidden="true" />
                    <motion.h5
                        variants={fadeIn("right", 0.3)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.7 }}
                        className="mb-2 text-2xl font-semibold tracking-tight text-white dark:text-white">
                       Desarrollo de Software
                    </motion.h5>
                    <p className="mb-3 font-normal text-white dark:text-gray-400">
                    Impulsamos tu negocio con software a medida, mejorando eficiencia, optimizando procesos, aumentando productividad, y generando resultados tangibles.
                    </p>
                    <span className="inline-flex font-medium items-center text-blue-600 hover:underline bg-gradient-to-r from-fuchsia-500 to-cyan-500 bg-clip-text text-transparent">
                        Ver Más
                        <svg className="w-3 h-3 ms-2.5 rtl:rotate-[270deg] ml-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
                        </svg>
                    </span>
                </Link>
                <Link to="/alojamiento-web/hosting/dominio"
                    className="m-5 max-w-sm p-6 bg-gray-900 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 text-[20px] lg:text-[22px] hover:scale-105 hover:shadow-lg transition-transform duration-300">
                    <img className="w-20 h-20 text-white dark:text-gray-400 mb-3 border-r-amber-300" src={hosting} alt="ads publicidad seo" aria-hidden="true" />
                    <motion.h5
                        variants={fadeIn("right", 0.3)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.7 }}
                        className="mb-2 text-2xl font-semibold tracking-tight text-white dark:text-white">
                        Hosting, Dominio y Cooporativo
                    </motion.h5>
                    <p className="mb-3 font-normal text-white dark:text-gray-400">
                    Transforma tu negocio con un hosting confiable, dominios estratégicos y correo corporativo que potencian tu presencia online y aceleran tu crecimiento. 
                    </p>
                    <span className="inline-flex font-medium items-center text-blue-600 hover:underline bg-gradient-to-r from-fuchsia-500 to-cyan-500 bg-clip-text text-transparent">
                        Ver Más
                        <svg className="w-3 h-3 ms-2.5 rtl:rotate-[270deg] ml-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
                        </svg>
                    </span>
                </Link>
            </div>
        </>
    )
}

export default Servicios
