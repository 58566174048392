import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import Banner from './components/Banner';
import Header from './components/Header';
import About from './components/About';
import Servicios from './components/Servicios';
// import Empresas from './components/Empresas';
import Contact from './components/Contact';
import Boton from "./components/BotonWsp";
import WhatsAppButton from './components/Whathsapp';
import Footer from './components/Footer';
import WebDesign from './pages/DiseñoWeb';
import RedesSociales from './pages/RedesSociales';
import GoogleAds from './pages/GoogleADS';
import Ecommerce from './pages/Ecommerce';
import Software from './pages/Software';
import ScrollToTop from "./components/ScrollToTop";
import Testimonial from './components/Testimonial';
import Hosting from './pages/Hosting';

// Configuración e inicialización de GTM
const TagManagerArgs = {  
  gtmId: 'GTM-PKV3F9N6'
};
TagManager.initialize(TagManagerArgs);

const MainLayout = () => (
  <div className='bg-site bg-no-repeat bg-cover overflow-hidden'>
    <Boton />
    <WhatsAppButton />
    <Header />
    <Banner />
    <Servicios />
    <About />
    {/* <Testimonial/> */}
    {/* <Empresas /> */}
    {/* <Testimonials/> */}
    <Contact />
    <Footer />
  </div>
);

const PageWithLayout = ({ children }) => (
  <div className='bg-site bg-no-repeat bg-cover overflow-hidden'>
    <Header />
    {children}
    <Footer />
  </div>
);

const App = () => {
  return (
    <Router>
      <ScrollToTop/>
      <Routes>
        <Route exact path="/" element={<MainLayout />} />
        <Route path="/diseno-web-profesional" element={<PageWithLayout><WebDesign /></PageWithLayout>} />
        <Route path="/marketing-redes-sociales" element={<PageWithLayout><RedesSociales /></PageWithLayout>} />
        <Route path="/publicidad-online" element={<PageWithLayout><GoogleAds /></PageWithLayout>} />  
        <Route path="/tiendas-online" element={<PageWithLayout><Ecommerce /></PageWithLayout>} />  
        <Route path="/desarrollo-software" element={<PageWithLayout><Software/></PageWithLayout>} />
        <Route path="/alojamiento-web/hosting/dominio" element={<PageWithLayout><Hosting/></PageWithLayout>} />    
      </Routes>
    </Router>
  );
};

export default App;