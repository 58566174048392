import React from 'react';
// intersection observer
import { useInView } from "react-intersection-observer";
// motion
import { motion } from "framer-motion";
// variant
import { fadeIn } from "../variants";
import innovacion from "../assets/innovacion (1).png";
import atencioncliente from "../assets/atencion-al-cliente.png";
import calidad from "../assets/premium.png";
import experiencia from "../assets/satisfaccion-del-consumidor.png";
import garantia from "../assets/garantia.png";
import trato from "../assets/ventas.png";
import about from "../assets/pana6.png";
import Testimonials from "../components/Testimonials";

const About = () => {
  const [ref,] = useInView({
    threshold: 0.5,
  });

  return (
    <>
    <section className="section min-h-[50vh] mb-3" id="nosotros" ref={ref}>
      <div className="container mx-auto">
        <div className="flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 min-h-[50vh] md:min-h-[80vh]">
          {/* img */}
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{
              once: false,
              amount: 0.3,
            }}
            className="flex-1 h-[300px] md:h-[400px] lg:h-[640px]"
          >
            <img src={about} alt="" className="w-full h-full object-contain" />
          </motion.div>

          {/* text */}
          <motion.div
            variants={fadeIn("left", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{
              once: false,
              amount: 0.3,
            }}
            className="flex-1"
          >
            <p className="text-[35px] md:text-[40px] lg:text-[60px] font-bold leading-[1] text-center mb-4">
              ¿Por qué elegir 
              <span className="inline-block relative bg-gradient-to-r from-fuchsia-500 to-cyan-500 bg-clip-text text-transparent dark:text-white">
              &nbsp;Nexus?
              </span>
            </p>
            <div className="mb-6 text-[18px] md:text-[20px] lg:text-[22px]">
              <div className="flex mb-3">
                <div className="m-2">
                  <img src={garantia} alt="" width={55} height={55} />
                </div>
                <p>
                  Experiencia Comprobada: Con más de 3 años en el sector, Nexus ofrece soluciones confiables y eficientes.
                </p>
              </div>
              <div className="flex mb-3">
                <div className="m-2">
                  <img src={innovacion} alt="" width={50} height={50} />
                </div>
                <p>
                  Innovación Constante: Nos mantenemos a la vanguardia de las últimas tecnologías y tendencias.
                </p>
              </div>
              <div className="flex mb-3">
                <div className="m-2">
                  <img src={calidad} alt="" width={45} height={45} />
                </div>
                <p>
                  Compromiso con la Calidad: La excelencia es nuestro estándar en cada proyecto.
                </p>
              </div>
              <div className="flex mb-3">
                <div className="m-2">
                  <img src={atencioncliente} alt="" width={50} height={50} />
                </div>
                <p>
                  Atención Personalizada: Ofrecemos un servicio adaptado a tus necesidades específicas.
                </p>
              </div>
              <div className="flex mb-3">
                <div className="m-2">
                  <img src={experiencia} alt="" width={50} height={50} />
                </div>
                <p>
                  Resultados Garantizados: Hemos ayudado a numerosas empresas a alcanzar sus objetivos.
                </p>
              </div>
              <div className="flex mb-3">
                <div className="m-2">
                  <img src={trato} alt="" width={70} height={70} />
                </div>
                <p>
                  Elegir Nexus es elegir seguridad, innovación y éxito. ¡Contáctanos hoy y da el primer paso hacia un futuro más brillante con Nexus!
                </p>
              </div>
            </div>
            <div className="flex gap-x-8 items-center">
              <a href="#contacto">
                <button className="btn btn-lg">Contáctanos</button>
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
    {/* <section className="min-h-[50vh] mb-9" >
  <Testimonials />
</section> */}
    </>
  );
};

export default About;

