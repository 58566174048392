import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { fadeIn } from "../variants";
import designweb from "../assets/designweb.png";
import WhatsAppButton from "../components/Whathsapp";

const DisenoWeb = () => {
  const navigate = useNavigate();

  const handleNavigateToContact = () => {
    navigate("/");
    setTimeout(() => {
      const section = document.querySelector("#contacto");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  return (
    <div className='bg-site bg-no-repeat bg-cover overflow-hidden min-h-[40rem] mt-4 '>
      <section className='py-16 lg:section' id='contacto'>
        <div className='container mx-auto'>
          <div className='flex flex-col lg:flex-row'>
            {/* Texto e imagen */}
            <motion.div
              variants={fadeIn("right", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className='flex-1 flex justify-start items-center'
            >
              <div>
                <h2 className='text-[40px] lg:text-[60px] leading-none mb-12 text-center flex justify-center'>
                  Creación de <br />Sitios Web 
                </h2>
                <img className='mb-6' src={designweb} width={450} height={450} alt="paginas web" />
              </div>
            </motion.div>

            {/* Texto descriptivo */}
            <motion.div
              variants={fadeIn("left", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{
                once: false,
                amount: 0.3,
              }}
              className="flex-1"
            >
              <p className="text-[25px] font-bold leading-[0.8] lg:text-[40px] text-center mb-4">
                ¿Por qué debería invertir en un sitio web profesional?
              </p>
              <div className="mb-6 text-[20px] lg:text-[22px]">
                <div className="flex mb-3">
                  <p>
                    En el mundo digital, tu sitio web es la cara de tu negocio. Un sitio web profesional atrae a más visitantes y los convierte en clientes. Nuestro servicio de desarrollo web personalizado crea páginas modernas, responsivas y optimizadas para SEO, asegurando que tu negocio destaque en los motores de búsqueda.
                  </p>
                </div>
                <div className="flex mb-3">
                  <p>
                    Al elegirnos para la creación de tu sitio web, obtienes:
                 
                      <li>Diseños personalizados que reflejan tu marca.</li>
                      <li>Sitios web responsivos, adaptados a móviles, tablets y ordenadores.</li>
                      <li>Optimización SEO para mejorar tu posicionamiento en Google.</li>
                      <li>Velocidad de carga optimizada para una mejor experiencia de usuario.</li>
                 
                  </p>
                </div>
                <div className="flex mb-3">
                  <p>
                    No dejes que la competencia te supere. Invierte en un sitio web profesional y haz crecer tu negocio.
                  </p>
                </div>
              </div>
              <div className="flex gap-x-8 items-center">
                <button className="btn btn-lg" onClick={handleNavigateToContact}>
                  Contáctanos
                </button>
              </div>
            </motion.div>
          </div>
        </div>
        <WhatsAppButton />
      </section>
    </div>
  );
};

export default DisenoWeb;
