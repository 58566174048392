import React from 'react';
//image
import Image from "../assets/Celular.png"
//icons

import { BsFacebook, BsInstagram, BsWhatsapp } from "react-icons/bs";

//type animation 
import { TypeAnimation } from 'react-type-animation';
//motion
import { motion } from "framer-motion"
//variants
import { fadeIn } from "../variants"
import curve from "../assets/curve.png"




const Banner = () => {
  return (
    <section className='min-h-[85vh] lg:min-h-[78vh] flex items-center' id='home'>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'>
          {/* text */}
          <div className='flex-1 text-center font-secondary lg:text-left'>
            <motion.h1
              variants={fadeIn("up", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className='text-[42px] font-bold leading-[0.8] lg:text-[75px] mb-[12px]'>
              En nuestra Agencia de <span className='inline-block relative '>Marketing
                <img
                  src={curve}
                  alt=""
                  className="absolute top-full left-0 w-full xl:-mt-1 "
                  width={624}
                  height={28}
                />
              </span>

            </motion.h1>
            <motion.div
              variants={fadeIn("up", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.4 }}
              className='mb-6 text-[36px] lg:text-[60px] font-secondary
          font-semibold uppercase leading-[1] '>
              {/* <span className=' text-white mr-4'>a tu negocio</span> */}
              <TypeAnimation sequence={[
                "Elevamos",
                2000,
                "Transformamos",
                2000,
                "Potenciamos",
                2000,
              ]}
                speed={50}
                className="text-accent"
                // className='bg-gradient-to-r from-fuchsia-500  to-cyan-500 bg-clip-text text-transparent '

                //71427321893 
                //8888 
                //519 7148

      
                wrapper='span'
                repeat={Infinity}
              />
            </motion.div>

            <motion.p
              variants={fadeIn("up", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className='text-[20px] lg:text-[22px] mb-8 max-w-lg mx-auto lg:mx-0'>Revolucionamos tu marca con estrategias digitales de alto impacto y creativas, somos el motor de tu éxito digital.</motion.p>
            <motion.div
              variants={fadeIn("up", 0.6)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className='flex max-w-max gap-x-6 items-center mb-10 mx-auto lg:mx-0'>
              <button className='btn btn-lg '>
                <a href="#contacto">Contactanos</a>
              </button>
            </motion.div>
            {/* social */}
            <motion.div
              variants={fadeIn("up", 0.7)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className='flex text-[40] gap-x-10 max-w-max mx-auto lg:mx-0'>

              <a href="https://www.facebook.com/profile.php?id=61564216423187" className="text-white hover:text-accent" target="_blank" rel="noopener noreferrer">
                <BsFacebook size={20} />
              </a>
              <a href="https://www.instagram.com/nexusmarketingd/" className="text-white hover:text-accent" target="_blank" rel="noopener noreferrer">
                <BsInstagram size={20} />
              </a>
              <a href="https://wa.me/5492613866469" className="text-white hover:text-accent" target="_blank" rel="noopener noreferrer">
                <BsWhatsapp size={20} />
              </a>

            </motion.div>
          </div>
          {/* image */}
          <motion.div
            variants={fadeIn("down", 0.5)}
            initial="hidden"
            whileInView={"show"}
            className='hidden lg:flex flex-1 max-w-[320px] lg:max-w-[482px]'>
            <img className='imageInitial' src={Image} alt="" />
          </motion.div>
        </div>

      </div>
    </section>
  );

};

export default Banner;
